import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subscription } from 'rxjs'
import { timeAgo } from '../../../helpers/helpers'
import { GeneralService } from '../../../services/general.service'
import { HomeService } from '../../../services/home.service'
import { MarkerService } from '../../../services/marker.service'
import { GeneralModalComponent } from '../../general-modal/general-modal.component'

@Component({
  selector: 'vehicle-template',
  templateUrl: './vehicle-template.component.html',
  styleUrls: ['./vehicle-template.component.css', '../device-template/device-template.component.css']
})
export class VehicleTemplateComponent implements OnInit {
  private subscription: Subscription = new Subscription;
  @Input() vehicle: any;
  @Input() isFreemium: boolean;
  @Output() runEntities = new EventEmitter<any>();

  vehicleHealth = [
    { name: 'Buena', type: 'good', icon: 'salud-buena.svg' },
    { name: 'Mala', type: 'bad', icon: 'salud-mala.svg' },
    { name: 'Estado critico', type: 'critical', icon: 'saludmuy-mala.svg' }
  ];
  menu: any = [];
  wiData: any;
  contexts: any;
  map: any;
  shwoShifts: boolean;
  shifts: any[] = [];
  selected: any;
  weekDays: any = [
    { day: 'Dom' },
    { day: 'Lun' },
    { day: 'Mar' },
    { day: 'Mié' },
    { day: 'Jue' },
    { day: 'Vie' },
    { day: 'Sáb' }
  ];

  showingRoute: boolean;
  mGroup: any;
  findText: string;
  sort: any;
  showAddVehicleTags = false;

  constructor(
    public gs: GeneralService,
    public homeService: HomeService,
    public markerService: MarkerService,
    public dialog: MatDialog,
  ) {
    this.subscription.add(this.gs.getWiFindText.subscribe(findText => this.findText = findText));
    this.subscription.add(this.gs.getWiContext.subscribe((data: any[]) => this.contexts = data));
    this.subscription.add(this.gs.getMap.subscribe((data: any) => this.map = data));
    this.subscription.add(this.gs.getShowLiveViewRoute.subscribe(show => this.showingRoute = show));
    this.subscription.add(this.markerService.getMGroup.subscribe(g => this.mGroup = g));
    this.subscription.add(this.gs.getWiData.subscribe(data => this.wiData = data));
    this.subscription.add(this.gs.getToolbarButtons.subscribe(buttons => this.menu = buttons));
    this.subscription.add(this.gs.getWiData.subscribe((data: any) => {
      if (data && data.type === 'vehicle') {
        this.selected = data;
      }
    }));
  }

  ngOnInit() {
    this.getShift();
    this.gs.getVehicleSort.subscribe(_ => this.sort = _);
    this.gs.logScreenEvents('vehicle_view','vehicle_view','vehicle_view','view');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getHealth(type) {
    return this.vehicleHealth.find(vh => vh.type === type);
  }

  fullName(name, lastName) {
    return `${name.split(' ')[0]} ${lastName.split(' ')[0]}`;
  }

  closePanel() {
    this.selected = undefined;
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.contexts });
  }

  getNameFirstLetter(name, lastName) {
    return `${name.length ? name.slice(0, 1)[0] : ''} ${lastName.length ? lastName.slice(0, 1)[0] : ''}`;
  }

  openTrips() {
    const context = this.contexts.filter(c => new RegExp(this.vehicle.type).test(c.context));
    this.gs.setWindow({ state: true, extendedState: false, type: 'trips', contexts: context, data: this.vehicle });
  }

  timeAgo(time) {
    return timeAgo(time);
  }

  async getShift() {
    const today = this.weekDays[new Date().getDay()];
    this.weekDays.map((wd, wi) => {
      today.day == wd.day ? this.weekDays[wi].today = true : false;
      this.vehicle.shifts ? this.vehicle.shifts.map((vs) => {
        this.vehicle.current_driver.shifts.map((ds) => {
          vs.day == wd.day && ds.day == wd.day ? this.weekDays[wi].active = true : false;
        });
      }) : [];
    });
  }

  shwoShiftsFn(d) {
    d.selected = !d.selected;
    this.shwoShifts = true;
    this.shifts = [];
    this.vehicle.shifts ? this.vehicle.shifts.map((vs) => {
      this.vehicle.current_driver.shifts.map((ds) => {
        if (vs.uid == ds.uid && d.day == ds.day) { this.shifts.push(vs); }
      });
    }) : [];
  }

  openExtension() {
    this.gs.setWindow({ state: true, extendedState: true, type: 'list', contexts: this.contexts, data: this.vehicle });
  }

  selectedVehicle(vehicle, panel) {
    if ( this.isFreemium && panel ) { panel._toggle(); }
    this.gs.setShowLiveViewRoute(false);
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.contexts, data: null});
    if (!vehicle || !vehicle.device) { return this.gs.addSelectedClass(false); }
    const marker = this.mGroup.findByImei(vehicle.device.imei);
    if (marker) {
      this.gs.goToMarker(marker, vehicle, this.map, this.contexts);
    } else {
      this.gs.addSelectedClass(false);
    }
  }

  openHealth() {
    this.gs.setWindow({
      state: true,
      extendedState: false,
      type: 'list',
      contexts: [this.menu.find(i => i.context === 'health')],
      data: Object.assign({}, { type: 'health' })
    });
  }

  openMaintenance() {
    this.gs.setWindow({
      state: true,
      extendedState: true,
      type: 'list',
      contexts: this.contexts,
      data: Object.assign({}, this.vehicle, { type: 'vehicle_maintenance' })
    });
  }

  editVehicle(vehicle) {
    this.openGeneralModal(vehicle, 'edit_vehicle');
    this.gs.logScreenEvents('vehicle_click_edit', 'vehicle_click_edit', 'vehicle_click_edit', 'click');
  }

  openGeneralModal(vehicle, type) {
    let width = '80vw';
    if (type === 'edit_vehicle') {
      width = '600px';
    }
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width,
      data: { ...vehicle, type }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.runEntities.emit(true);
    });
  }

  showOptions() {
    this.showAddVehicleTags = true;
  }

  addTagsClose() {
    this.showAddVehicleTags = false;
  }

  addTagsUpdated() {
    this.runEntities.emit(true);
    this.showAddVehicleTags = false;
  }

}
