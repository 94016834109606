import { Component, Input, OnInit } from '@angular/core'
import { take } from 'rxjs/operators'
import { translationPipe } from '../../../../pipes/translation/translation.pipe'
import { DriverService } from '../../../../services/driver.service'
import { GeneralService } from '../../../../services/general.service'
import { RequestService } from '../../../../services/request.service'
import { VehicleService } from '../../../../services/vehicle.service'

const emailregex: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const addValue = 'add+';

@Component({
  selector: 'app-edit-vehicle',
  templateUrl: './edit-vehicle.component.html',
  styleUrls: ['./edit-vehicle.component.css']
})
export class EditVehicleComponent implements OnInit {

  @Input('data') vehicle: any;
  @Input('dialogRef') dialogRef: any;
  brands: any = [];
  models: any = [];
  form: any = {
    device: {},
    vehicle: {},
    user: {}
  };
  years: any = [];
  drivers: any = [];
  vehicles: any = [];
  alert: any;
  addValue = addValue;

  constructor(
    private rs: RequestService,
    private gs: GeneralService,
    private ds: DriverService,
    private vs: VehicleService
  ) {
  }

  ngOnInit() {
    this.getBrands();

    this.ds.getCurrentDrivers.pipe(take(1)).subscribe(drivers => {
      this.drivers = drivers;
      this.setData();
    });

    this.vs.getCurrentVehicles.subscribe(vehicles => this.vehicles = vehicles);
  }

  async getBrands() {
    this.brands = await this.rs.request({
      method: 'GET',
      endpoint: 'get_brands'
    });
    this.setData('brand');
    this.getModels();
  }

  async getModels() {
    if (!this.form.vehicle.brandId || this.form.vehicle.brandName === addValue) { return; }
    const resp = await this.rs.request({
      method: 'GET',
      endpoint: 'get_models',
      brandId: this.form.vehicle.brandId
    });

    if (!resp.error) {
      this.models = resp;
      this.setData('model');
    } else {
      this.models = [];
    }
  }

  setData(model: string = null) {
    const vehicleUpdate = ['brand', 'model', 'year'].some(m => m === model);

    const { id: brandId, name: brandName }: any = this.brands
      .find(b => b.name === (this.form.vehicle.brandName || this.vehicle.brand)) || {};

    const { id: modelId, name: modelName, years: modelYears }: any = this.models
      .find(b => b.name === (this.form.vehicle.modelName || this.vehicle.model)) || {};

    const { firstName, lastName, phone, email, id: driverId }: any = this.drivers
      .find(d => d.uuid === this.vehicle.drivers[0]) || {};

    // assign all years from next year to the last 15 ones if this years is empty
    const years = modelYears && modelYears.length
      ? modelYears.map(i => i.year)
      : Array.from({ length: 25 }, (v, k) => ((new Date()).getFullYear() + 1 - k));

    this.years = years.sort((a: any, b: any) => a.year - b.year);

    const { id: deviceId, imei }: any = this.vehicle.device;

    this.form.device = {
      id: deviceId,
      imei
    };

    let brandN = this.form.vehicle.brandName !== addValue ? brandName : addValue;
    let modelN = this.form.vehicle.modelName !== addValue ? modelName : addValue;

    if (!brandName && vehicleUpdate) {
      brandN = addValue;
      this.form.vehicle.brandOptional = this.vehicle.brand;
    }

    if (!modelName && (brandN === addValue || ['model', 'year'].some(m => m === model))) {
      modelN = addValue;
      this.form.vehicle.modelOptional = this.vehicle.model;
    }

    this.form.vehicle = {
      ...this.form.vehicle,
      id: this.vehicle.id,
      brandId: brandId || undefined,
      brandName: brandN,
      modelId: brandId ? modelId || undefined : undefined,
      modelName: modelN,
      year: this.form.vehicle.year || this.vehicle.year || undefined,
      plate: brandId ? this.form.vehicle.plate || this.vehicle.plate : undefined
    };

    if (!vehicleUpdate) {
      this.form.user = {
        id: driverId || undefined,
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        phone: phone || undefined,
        email: email || undefined
      };
    }

    if (!this.form.vehicle.brandName || !this.models) {
      this.models = [];
    }

    if (!this.form.vehicle.brandName || !this.form.vehicle.modelName || !this.years) {
      this.form.vehicle.year = undefined;
      this.years = [];
    }

  }

  async saveVehicle() {
    const { error }: any = this.validateFields() || {};
    if (error) {
      this.alert = this.gs.openAlert({ msg: error, error: true });
      return;
    }

    this.alert && this.alert.dismiss();

    const body = {
      ...this.form,
      device: undefined,
      vehicle: {
        ...this.form.vehicle,
        patent: this.form.vehicle.plate,
      }
    };

    if (body.vehicle.brandName === addValue) {
      body.vehicle.brandName = body.vehicle.brandOptional;
    }

    if (body.vehicle.modelName === addValue) {
      body.vehicle.modelName = body.vehicle.modelOptional;
    }

    delete body.vehicle.brandOptional;
    delete body.vehicle.modelOptional;
    delete body.vehicle.plate;

    const res = await this.rs.request({
      method: 'PUT',
      endpoint: 'update_vehicle',
      body: [body]
    });

    if (res.error || res[0].response.message !== 'success') {
      return this.gs.openAlert({ msg: res[0].response.message, error: true });
    }

    this.gs.setReloadEntities(true);
    this.gs.openAlert({ msg: 'ONBOARDING.VEHICLE_SAVED' });
    this.backToList();
    this.gs.logScreenEvents('vehicle_click_save', 'vehicle_click_save', 'vehicle_click_save', 'click');
  }

  validateFields() {
    const { user: { email }, vehicle: { plate } } = this.form;
    if (email !== '' && email && !emailregex.test(String(email).toLowerCase())) {
      return { error: translationPipe.transform('ONBOARDING.EMAIL_ERROR') };
    }

    if (plate && plate.length > 10) {
      return { error: translationPipe.transform('ONBOARDING.PLATE_ERROR') };
    }
  }

  async backToList() {
    this.dialogRef.close();
  }

}
